export class Trackers {

	constructor(private gtagId: string) {
	}
	
	disable() {
		/// @ts-ignore
		window['ga-disable-' + this.gtagId] = true
		// https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out
	}

	enable() {
		/// @ts-ignore
		window['ga-disable-' + this.gtagId] = false;
		/// @ts-ignore
		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		/// @ts-ignore
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		/// @ts-ignore
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','GTM-THF2C2S');
	}
}