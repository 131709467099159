import '../sass/theme.scss'

import { mountPage } from './utils/mountPage'
import { setupBlockAnimations } from './utils/setupBlockAnimations'
import { setupPageTransitions } from './utils/setupPageTransitions'


window.addEventListener('load', () => {
	
	mountPage()
	setupPageTransitions()
	setupBlockAnimations()

	document.body.classList.add('ready')
});
