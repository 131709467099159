import { Callback, EventSystem } from "../utils/EventSystem"

type FloatingBannerStatus = 'accept' | 'reject'

export interface FloatingBannerOptions {
	onTrackersEnable: Callback,
	onTrackersDisable: Callback,
}

export class FloatingBanner {

	private openClass = 'floating-banner--open'
	private storageKey = 'floating-banner-status'

	private _events: EventSystem = new EventSystem()
	public get events() { return this._events }

	constructor (private $root: HTMLElement, options: Partial<FloatingBannerOptions> = {}) {
		console.log('floating banner', $root)

		const $acceptBt = $root.querySelector('.floating-banner__accept') as HTMLButtonElement
		const $rejectBt = $root.querySelector('.floating-banner__reject') as HTMLButtonElement

		$acceptBt.addEventListener('click', () => {
			this.writeAcceptanceStatus('accept')
			this.toggleOpen(false)
			this.enableTrackers()
		})

		$rejectBt.addEventListener('click', () => {
			this.writeAcceptanceStatus('reject')
			this.toggleOpen(false)
			this.disableTrackers()
		})

		if (options.onTrackersEnable) this.events.on('trackers:enable', options.onTrackersEnable)
		if (options.onTrackersDisable) this.events.on('trackers:disable', options.onTrackersDisable)

		this.init()
	}

	get isOpen() {
		return this.$root.classList.contains(this.openClass)
	}

	private init() {
		const acceptanceStatus = this.readAcceptanceStatus()

		switch(acceptanceStatus) {
			case 'accept': this.enableTrackers(); break;
			case 'reject': this.disableTrackers(); break;
			default: 
				this.disableTrackers()
				this.toggleOpen(true)
		}
	}

	toggleOpen(status?: boolean) {
		if (status === undefined) {
			status = !this.isOpen
		}

		this.$root.classList.toggle(this.openClass, status)
	}

	private writeAcceptanceStatus(status: FloatingBannerStatus) {
		sessionStorage.setItem(this.storageKey, status)
	}

	private readAcceptanceStatus() {
		return sessionStorage.getItem(this.storageKey) as FloatingBannerStatus | undefined
	}

	private enableTrackers() {
		console.log('trigger enable trackers')
		this.events.trigger('trackers:enable')
	}

	private disableTrackers() {
		this.events.trigger('trackers:disable')
	}
}