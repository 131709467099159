import { Intersector } from './Intersector'

export class EarlyIntersector extends Intersector {

	private thresholds: number[];

	constructor(public $roots: HTMLElement | HTMLElement[]) {
		const thresholds = [0, 0.25]
		super($roots, thresholds)
		this.thresholds = thresholds
	}

	protected onIntersection(entries: IntersectionObserverEntry[]) {
		entries.forEach(e => e.target.setAttribute('data-intersection', (e.intersectionRatio > this.thresholds[1] ? '1' : '0')))
	}
}