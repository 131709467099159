import { allPages } from '../pages/all'

export function mountPage() {
	const loadPageModule = (name: string, $root: HTMLElement) => {
		import(`../pages/${name}.ts`).then(m => new (m.default)($root))
	}
	const pages = ['home']

	const $page = document.querySelector('.page') as HTMLElement

	pages.forEach(pageName => {
		if ($page?.classList.contains('page--' + pageName)) {
			loadPageModule(pageName, $page)
		}
	})

	allPages()
}