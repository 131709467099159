import barba from '@barba/core'
import { mountPage } from './mountPage'
import { setupBlockAnimations } from './setupBlockAnimations'

export function setupPageTransitions () {
	
	const transitionDuration = 400

	barba.init({
		transitions: [
			{
				name: 'fade',
				leave() {
					return new Promise<void>((resolve: () => void) => {
						document.body.classList.add('fade')
						setTimeout(() => resolve(), transitionDuration)
					})
				},
				enter() {
					document.body.classList.remove('fade')
					setTimeout(() => mountPage())
					setupBlockAnimations()
				}
			}
		]
	})
}