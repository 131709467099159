
export type Callback = (...args: any) => void
type Dictionary<T> = {[k: string]: T}

export class EventSystem {
	private cbs: Dictionary<Callback[]> = {}

	on(event: string, cb: Callback) {
		if (!this.cbs[event]) this.cbs[event] = []
		this.cbs[event].push(cb)
	}

	trigger(event: string, ...args: any) {
		this.cbs[event]?.forEach(cb => cb(...args))
	}
}