import { CardAccordion } from "../components/CardAccordion"
import { FloatingBanner } from "../components/FloatingBanner"
import { Menu } from "../components/Menu"
import { Trackers } from "../utils/trackers"

export const allPages = () => {
	
	const $page = document.querySelector('.page') as HTMLElement

	const $menu = document.querySelector('.menu') as HTMLElement
	if ($menu) new Menu($menu, $page)

	const $cards = Array.from(document.querySelectorAll('.card-accordion')) as HTMLElement[]
	if ($cards.length) {
		$cards.forEach($card => new CardAccordion($card))
	}

	const trackers = new Trackers('GTM-THF2C2S')
	const $floatingBanners = Array.from(document.querySelectorAll('.floating-banner')) as HTMLElement[]
	$floatingBanners.forEach($floatingBanner => {
		new FloatingBanner($floatingBanner, {
			onTrackersEnable: () => trackers.enable(),
			onTrackersDisable: () => trackers.disable(),
		})
	})

	const internalNavigation = () => {
		if (window.location.hash != '') {
			const $target = window.document.querySelector(window.location.hash)
			if ($target) {
				console.log('scroll into view')
				$target.scrollIntoView()
			}
		}
	}

	internalNavigation()

	const wrapCtasContent = () => {
		const $ctas = Array.from(window.document.querySelectorAll('.cta'))
		$ctas.forEach($cta => {
			const wrapper = document.createElement('div')
			wrapper.innerHTML = $cta.innerHTML
			$cta.innerHTML = ''
			$cta.append(wrapper)
		})
	}

	wrapCtasContent()

	const setBlankTargets = () => {
		window.document.querySelectorAll('a')
			.forEach($cta => {
				const isPdf = $cta.href.match(/\.pdf$/)
				if (isPdf) $cta.target = '_blank'
				// console.log($cta.href, isPdf ? 'is pdf' : '')
			})
	}

	setBlankTargets()
}