export class Menu {

	private $root: HTMLElement;
	private hover: boolean = false;
	private timer?: number;
	private cooldownMillis = 3000;

	constructor($root: HTMLElement, scrollParent: HTMLElement) {

		this.$root = $root

		scrollParent.addEventListener('scroll', () => {
			if (scrollParent.scrollTop > window.innerHeight) {
				this.open()
			}

			if (!this.hover) {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => this.close(), this.cooldownMillis, [])
			}
		})

		$root.addEventListener('mouseover', () => {
			this.hover = true
			clearTimeout(this.timer)
		})

		$root.addEventListener('mouseout', () => {
			this.hover = false
			this.timer = setTimeout(() => this.close(), this.cooldownMillis, [])
		})
	}

	open() {
		this.$root.classList.add('open')
	}

	close() {
		this.$root.classList.remove('open')
	}
}