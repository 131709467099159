export class Intersector
{
	private observer?: IntersectionObserver;

	constructor(
		public $roots: HTMLElement | HTMLElement[],
		thresholds: number | number[]
	) {
		if (!Array.isArray($roots)) {
			this.$roots = [this.$roots as HTMLElement];
		}

		this.watchIntersection(this.$roots as HTMLElement[], thresholds, e => this.onIntersection(e))
	}

	protected onIntersection(entries: IntersectionObserverEntry[]) {
		entries.forEach(e => e.target.setAttribute('data-intersection', '' + Math.round(e.intersectionRatio)))
	}

	private watchIntersection($targets: HTMLElement[], thresholds: number | number[], cb: (e: IntersectionObserverEntry[]) => void) {
		if (!this.observer) {
			this.observer = new IntersectionObserver(
				cb,
				{
					root: null,
					rootMargin: '0px 0px -50px 0px',
					threshold: thresholds
				}
			)
		}

		$targets.forEach($t => this.observer?.observe($t))
	}
}